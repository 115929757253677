import { useState } from 'react';
import AppNav from '../../components/navigation/AppNav';
import LuaLoader from '../../components/api/LuaLoader';
import AppFooter from '../../components/navigation/AppFooter';
import { useParams, useSearchParams } from 'react-router-dom';

// Example URL https://accounts.giganticplay.com/oauth2/bnet/callback?code=e8d85b6ee8ee72cb97a437b8f9c98658&state=8opw0KhFrA6Vqa5S0kRCRGXTFMAKixo-wsI1LGKnZKw

export default function OAuth2Callback() {
  const [searchParams] = useSearchParams();
  const [oauthState] = useState(searchParams.get('state'));
  const [oauthCode] = useState(searchParams.get('code'));
  const { slug } = useParams();

  if (oauthState && oauthCode) {
    switch (slug) {
      case 'bnetdev':
        window.location.href = `http://localhost:5000/auth/callback?code=${oauthCode}&state=${oauthState}`;
        break;
      case 'bnet':
        window.location.href = `https://towerdb.giganticplay.com/auth/callback?code=${oauthCode}&state=${oauthState}`;
        break;
      default:
        break;
    }
  }

  return (
    <main
      className={
        'relative flex h-full min-h-screen w-full flex-col items-center justify-center space-y-6'
      }
    >
      <AppNav />
      <div
        className={
          'relative flex h-[25.6rem] max-h-[25.6rem] w-full max-w-[35rem] flex-col items-start justify-center space-y-3 overflow-hidden rounded-xl border border-brand-800 py-6 px-6'
        }
      >
        <LuaLoader size={64} />
      </div>
      <AppFooter />
    </main>
  );
}
