export default function AppFooter() {
  return (
    <footer
      className={
        'relative flex h-fit w-full max-w-[35rem] flex-row-reverse items-center justify-between overflow-hidden rounded-xl border border-brand-800 p-6'
      }
    >
      <nav className={'relative flex items-center justify-center space-x-3'}>
        <a
          href={'https://streaming.giganticplay.com/legal/impressum'}
          target={'_blank'}
          rel={'noopener noreferrer'}
          className={'text-brand-500 hover:text-brand-50'}
        >
          Imprint
        </a>
        <a
          href={'https://streaming.giganticplay.com/legal/datenschutz'}
          target={'_blank'}
          rel={'noopener noreferrer'}
          className={'text-brand-500 hover:text-brand-50'}
        >
          Privacy Policy
        </a>
      </nav>
      <span>©{new Date().getFullYear()} GiganticPlay</span>
    </footer>
  );
}
