import PropTypes from 'prop-types';
import LuaLoaderPNG from '../../assets/images/content/Lua-Loader.svg';

export default function LuaLoader(props) {
  return (
    <div
      className={`relative m-auto grid h-[${props.size}px] w-[${props.size}px] place-items-center p-6`}
    >
      <img
        src={LuaLoaderPNG}
        alt={'Loading...'}
        width={props.size}
        height={props.size}
        className={'animate-spin'}
      />
    </div>
  );
}

LuaLoader.propTypes = {
  size: PropTypes.number.isRequired
};
