import './styles/App.scss';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import OAuth2Callback from './pages/oauth2/Callback';
import ErrorPage from './error-page';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={'/oauth2/:slug/callback'}
        exact
        element={<OAuth2Callback />}
        errorElement={<ErrorPage />}
      />
      <Route path="*" element={<ErrorPage />} errorElement={<ErrorPage />} />
    </>
  )
);

function App() {
  return (
    <div id="layout">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
