import GiganticLogo from '../../assets/images/theme/PlayLogoColor.svg';
import { Key } from 'phosphor-react';

export default function AppNav() {
  return (
    <header
      className={
        'relative flex h-fit w-full max-w-[35rem] flex-row items-center justify-between overflow-hidden rounded-xl border border-brand-800 p-6'
      }
    >
      <img src={GiganticLogo} alt={'GiganticPlay Logo'} className={'h-10'} />
      <span
        className={
          'relative flex flex-row items-center justify-center pl-3 font-display text-xl font-medium'
        }
      >
        <Key size={32} color={'currentColor'} className={'mr-1.5 text-primary-700'} />
        Auth
      </span>
    </header>
  );
}
